<template>
   <div class="col-md-6 ps-md-0">
      <div class="card mb-1" :class="index % 4 == 0 || index % 4 == 3 ? 'invert' : ''">
         <div class="card-body p-12">
            <h1 class="font-15 mb-0 limitador-1 text-uppercase">
               <span class="font-17" v-if="historico.preco != null">R$ {{ parseFloat(historico.preco).toFixed(2) }}</span>
               <span v-else-if="historico.quantidade != null"><span>{{ historico.nomeEstoque }} </span>(<small class="font-12">x </small>{{ historico.quantidade }})</span>
               <span v-else>Desconhecido</span>
            </h1>
            <p class="font-10 mb-1 limitador-1 text-secondary">
               <span class="me-3" v-if="historico.tipo"><i class="far fa-info-circle font-8 me-1"></i>{{ historico.tipo }}</span>
               <span><i class="far fa-calendar font-8 me-1"></i>{{ historico.data }}</span>
            </p>
            <p class="font-12 mb-0 limitador-2">
               <i class="far fa-user color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Usuário:</strong>
               <span> {{ historico.nomeUsuario == null || String(historico.nomeUsuario).trim().length == 0 ? 'Usuário não encontrado' : historico.nomeUsuario }}</span>
            </p>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Historico',
   props: ['historico', 'index']
}

</script>