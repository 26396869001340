<template>
   <div class="px-0 mb-1" ref="produto" :class="config.estoquesHabilitados.length > 3 ? 'col-12' : 'col-xxl-6 pe-xxl-1'">
      <div class="card mb-0" :class="config.estoquesHabilitados.length > 3 ? index % 2 == 0 ? 'invert': '' : index % 4 == 0 || index % 4 == 3 ? 'invert' : ''">
         <div class="card-body px-12 pt-12 pb-2">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-md-block">
                  <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-sm text-center text-sm-start">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                        <p class="font-10 mb-0 mb-md-1 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-tags font-9 me-1"></i>
                              <span>{{ produto.familia == null || String(produto.familia).trim().length == 0 ? 'Sem família' : produto.familia }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Código(s):</strong>
                           <span> {{ produto.codigos == null || String(produto.codigos).trim().length == 0 ? 'Sem código' : produto.codigos }}</span>
                        </p>
                     </div>
                     <!-- <div class="col-5 d-none d-xl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-bookmark color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Marca:</strong>
                           <span> {{ produto.marca == null || String(produto.marca).trim().length == 0 ? 'Sem marca' : produto.marca }}</span>
                        </p>
                     </div> -->
                  </div>
               </div>
               <div class="w-max-content text-center font-16 mx-auto me-sm-0 ms-sm-3 mt-2 mt-sm-0">
                  <div class="btn-icone color-theme">
                     <span @click="historico"><i class="far fa-history"></i><small>Histórico</small></span>
                  </div>
               </div>

               <div class="col-12">
                  <hr class="mt-2 mb-12">
                  <div class="d-flex overflow-auto pb-2">
                     <div class="wpx-125 d-none d-md-flex justify-content-center align-items-center lh-1 me-2">
                        <i class="fal fa-boxes font-12 me-2"></i>
                        <span class="d-block font-13 text-center"><span class="d-block">Estoques</span><span class="d-block">selecionados</span></span>
                     </div>
                     <estoque v-for="(estoque, index) in produto.quantidades" :key="index" :estoque="estoque" :index="index" :produto="produto" :config="config" 
                        :class="config.estoquesHabilitados.find(e => e.id == estoque.idEstoque) ? '' : 'd-none'" @addTemp="addTemp($event)" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import estoque from '@/components/produtos/estoque/Estoque.vue'

export default {
   name: 'Produto',
   props: ['produto', 'index', 'config'],
   components: {
      estoque
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      historico : function () {
         this.$emit('historico', this.produto)
      },
      addTemp : function (editado) {
         this.$emit('addTemp', editado)
      }
   }
}

</script>

<style scoped>

.col-md {
   min-width: 290px;
}

</style>