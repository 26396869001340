<template>
   <div class="wpx-175 text-center lh-1 me-2">
      <label><i class="far fa-box color-theme font-10 me-1"></i> {{ estoque.nomeEstoque }}</label>
      <div v-if="config.modo == 'Acumulativo'" class="input-group">
         <span class="input-group-text input-sm font-12">+</span>
         <input type="text" class="form-control input-sm" v-model="editado.acumulativo" :disabled="!dadosUsuario.usuarioPermissoes.includes('Produtos | Editar estoque produto')" />
      </div>
      <input v-else type="text" class="form-control input-sm" v-model="editado.quantidade" :disabled="!dadosUsuario.usuarioPermissoes.includes('Produtos | Editar estoque produto')" />
      <small v-if="config.modo == 'Acumulativo'" class="font-11">Atual: {{ estoque.quantidade }}</small>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Estoque',
   props: ['estoque', 'index', 'config', 'produto'],
   data : function () {
      return {
         editado: this.config.estoquesEditados.find(p => p.idEstoque == this.estoque.idEstoque && p.id == this.produto.id) != null ? 
            this.config.estoquesEditados.find(p => p.idEstoque == this.estoque.idEstoque && p.id == this.produto.id) : 
            {
               'id': this.produto.id, 
               'nome': this.produto.nome,
               'codigos': this.produto.codigos == null || String(this.produto.codigos).trim().length == 0 ? 'Sem código' : this.produto.codigos,
               'idEstoque': this.estoque.idEstoque, 
               'quantidade': this.produto.isDecimal ? parseFloat(this.estoque.quantidade).toFixed(3) : parseInt(this.estoque.quantidade), 
               'acumulativo': null
            }
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   watch: {
      'editado.acumulativo' : function (newVal) {
         if (newVal != null) {
            this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': this.produto.isDecimal ? 3 : 0}).then((valor) => {
               this.editado.acumulativo = valor
             
               let envio = JSON.parse(JSON.stringify(this.editado))
               envio.quantidade = parseFloat(valor) + parseFloat(this.estoque.quantidade)

               this.$emit('addTemp', envio)
            })
         }
      },
      'editado.quantidade' : function (newVal, oldVal) {
         if (newVal != null) {
            this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': this.produto.isDecimal ? 3 : 0}).then((valor) => {
               this.editado.quantidade = valor
             
               if (parseFloat(oldVal) != parseFloat(newVal) && this.estoque.quantidade != valor) this.$emit('addTemp', this.editado)
            })
         }
      }
   }
}

</script>

<style scoped>

small {
   display: block;
   margin-top: 4px;
}

.wpx-175 {
   min-width: 175px;
}

</style>