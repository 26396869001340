<template>
	<div class="row align-items-start">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
			<div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
				<strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
				<span>Gerencie a quantidade de produtos em seus estoques rapidamente.</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
			</div>
		</div>

		<div class="nav nav-pills cards-150 cards-sm-200 mb-2" id="estoque-tab" role="tablist" aria-orientation="vertical">
			<button v-for="(estoque, index) in listaEstoques" :key="index" class="nav-link cursor-auto mx-0" type="button" role="tab">
				<div class="form-check form-switch h-auto mb-0">
					<div class="row align-items-center">
						<div class="w-max-content"><input class="form-check-input mt-0" type="checkbox" :id="'estoque'+ index" @click="toggleEstoque(estoque)" :checked="estoque.habilitado"></div>
						<div class="col px-0">
							<label class="form-check-label cursor-pointer" :for="'estoque'+ index">
								<span class="limitador-1 mb-2">{{ estoque.nome }}</span>
								<span class="limitador-1 text-secondary font-10">
									<i class="far fa-store font-8 me-1"></i>{{ estoque.nomeLoja }}<small class="mx-1">|</small><i class="far fa-tag font-8 me-1"></i> {{ estoque.numeroLoja }}
								</span>
							</label>
						</div>
					</div>
				</div>
			</button>
			<button v-if="listaEstoques == null || listaEstoques.length == 0" class="nav-link cursor-auto mx-0" type="button" role="tab">
				<div class="form-check form-switch h-auto text-center mb-0 p-0">
					<i class="fal fa-box-open font-35 text-secondary opacity-50 d-block mb-3"></i>
					<h4 class="font-11 mt-3 mb-0 text-secondary text-uppercase">Nenhum estoque encontrado</h4>
				</div>
			</button>
		</div>

		<div class="col-lg">
			<div class="row">
				<div id="search-nav" class="col-12 px-0 d-block btn-group dropup">
					<button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
						<i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
					</button>
					<div class="card mb-2 dropdown-menu dropdown-menu-left">
						<div class="card-body pb-0">
							<div class="row">
								<div class="col-6 col-md-4 col-xl-2 mb-3">
									<label><i class="far fa-file color-theme font-11 me-1"></i> Categoria</label>
									<v-select name="Desktop" :options="listaCategorias" v-model="pesquisa.categoria" label="nome" :reduce="c => c.nome" placeholder="Todas as categorias" 
										@input="buscarSubcategorias(pesquisa.categoria)">
										<template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Categorias" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.categoria" @change="buscarSubcategorias(pesquisa.categoria)">
										<option v-for="(option, index) in listaCategorias" :key="index" :value="option.nome">{{ option.nome }}</option>
									</select>
								</div>
								<div class="col-6 col-md-4 col-xl-2 mb-3">
									<label><i class="far fa-copy color-theme font-11 me-1"></i> Subcategoria</label>
									<v-select name="Desktop" :options="listaSubcategorias" v-model="pesquisa.subcategoria" placeholder="Todas as subcategorias">
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span>
											<small class="d-block lh-sm mb-1 opacity-90 font-11" v-if="pesquisa.categoria">Utilize a tela "Categorias" para adicionar</small>
											<small class="d-block lh-sm mb-1 opacity-90 font-11" v-else>Selecione uma categoria</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.subcategoria">
										<option v-for="(option, index) in listaSubcategorias" :key="index" :value="option">{{ option }}</option>
									</select>
								</div>
								<div class="col-6 col-md-4 col-xl-2 mb-3">
									<label><i class="far fa-bookmark color-theme font-11 me-1"></i> Marca</label>
									<v-select name="Desktop" :options="listaMarcas" v-model="pesquisa.marca" label="nome" :reduce="m => m.nome" placeholder="Todas as marcas">
										<template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Marcas" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.marca">
										<option v-for="(option, index) in listaMarcas" :key="index" :value="option.nome">{{ option.nome }}</option>
									</select>
								</div>
								<div class="col-6 col-md-10 col-xl-5 mb-3">
									<label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome/Código/Família</label>
									<input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarProdutos" placeholder="Busque por nome do produto, código ou família" />
								</div>
								<div class="col-12 col-md-2 col-xl-1 mb-3 align-self-end">
									<button type="button" class="btn btn-primary w-100" @click="buscarProdutos"><i class="fas fa-search font-13"></i></button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 px-0" v-if="pesquisa.retorno.resultado.length > 0">
					<div class="card mb-0">
						<div class="card-body p-12">
							<div class="row">
								<div class="col-sm mb-2 align-self-center">
									<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
								</div>
								<div class="w-max-content mb-2">
									<div class="row align-items-center justify-content-end">
										<div class="w-max-content pe-2"><i class="fal fa-box font-12"></i><span class="ms-1 d-none d-md-inline"> Tipo de contagem</span></div>
										<div class="wpx-175" v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Editar estoque produto')">
											<v-select name="Desktop" class="w-100 select-sm" :options="['Substituição', 'Acumulativo']" v-model="config.modo" :clearable="false" :searchable="false">
												<template v-slot:no-options>Sem resultados</template>
											</v-select>
											<select name="Mobile" class="form-control select-sm" v-model="config.modo">
												<option v-for="(option, index) in ['Substituição', 'Acumulativo']" :key="index" :value="option">{{ option }}</option>
											</select>
										</div>
									</div>
								</div>
								<div class="w-max-content mb-2" v-if="config.estoquesEditados.length > 0">
									<a data-bs-toggle="offcanvas" href="#offcanvasEstoque" role="button" aria-controls="offcanvasEstoque" class="badge badge-default bg-theme pulse-effect mb-1">
										<i class="far fa-box font-10 me-1"></i> Estoques alterados
									</a>
								</div>
								<div class="w-max-content mb-2">
									<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
								</div>
							</div>
							<div class="row">
								<produto v-for="(produto, index) in pesquisa.retorno.resultado" :key="index" :produto="produto" :index="index" :config="config" 
									@addTemp="addTemp($event)" @historico="getHistorico($event)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- modalHistoricoEstoque -->
		<div class="modal fade" id="modalHistoricoEstoque" tabindex="-1" aria-labelledby="modalHistoricoEstoqueLabel" aria-hidden="true">
			<div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content h-100">
					<div class="modal-header">
						<h5 class="modal-title" id="modalHistoricoEstoqueLabel">Histórico do produto ({{ modal.nome }})</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<historico v-for="(historico, index) in modal.historico" :key="index" :historico="historico" :index="index" />

							<div class="col-12 my-5 text-center" v-if="modal.historico.length == 0">
								<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum histórico encontrado</h4>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>

		<!-- offcanvasEstoque -->
		<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasEstoque" aria-labelledby="offcanvasEstoqueLabel">
			<div class="offcanvas-header">
				<h5 id="offcanvasRightLabel">Estoques alterados</h5>
				<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body">
				<editado v-for="(editado, index) in config.estoquesEditados" :key="index" :editado="editado" :index="index" @remover="removerTemp($event)" />

				<div class="col-12 my-5 text-center" v-if="config.estoquesEditados.length == 0">
					<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
					<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
				</div>
			</div>
			<div class="offcanvas-footer">
				<button type="button" class="btn btn-primary" @click="salvarEstoqueProduto" v-if="config.estoquesEditados.length > 0">Salvar</button>
				<button type="button" class="btn btn-secondary" data-bs-dismiss="offcanvas">Fechar</button>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import historico from '@/components/modelos/Historico.vue'
import editado from '@/components/produtos/estoque/Editado.vue'
import produto from '@/components/produtos/estoque/Produto.vue'

export default {
	name: 'EstoquesProduto',
	data: function () {
		return {
			pesquisa: {
				'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 
				'retorno': {'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'resultado': []}
			},
			listaEstoques: [],
			listaSubcategorias: [],
			modal: {'id': null, 'nome': null, 'lojas': [], 'historico': []},
			config: {'estoquesHabilitados': [], 'estoquesEditados': [], 'modo': 'Substituição'}
		}
	},
	computed: {
      ... mapState({
         listaCategorias: state => state.listaCategorias,
         dadosUsuario: state => state.dadosUsuario,
         listaMarcas: state => state.listaMarcas,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
		'config.modo': function () {
         this.config.estoquesEditados = []
      }
	},
	components: {
		produto, historico, editado
	},
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Estoques por produto',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos':  ['id', 'nome', 'codigos', 'marca', 'categoria', 'subcategoria', 'familia'], 
				'lista': {'nome': 'quantidades', 'campos': ['nomeEstoque', 'quantidade']}
			})
		},
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		toggleEstoque : function (estoque) {
			let index = this.config.estoquesHabilitados.findIndex(e => e.id == estoque.id)

			if (index != -1) {
				this.config.estoquesHabilitados.splice(index, 1)
			} else {
				this.config.estoquesHabilitados.push(estoque)
			}
		},
		buscarEstoques : function () {
			this.$store.dispatch('getEstoques').then((data) => {
				this.listaEstoques = data
				
				if (this.config.estoquesHabilitados.length == 0 && this.listaEstoques.length > 0) {
					this.listaEstoques[0].habilitado = true
					this.config.estoquesHabilitados.push(this.listaEstoques[0])
				}
				
				this.listaEstoques.forEach(novo => {
					novo.editados = []
					this.config.estoquesHabilitados.forEach(antigo => {
						novo.editados = antigo.editados
						if (novo.id == antigo.id) novo.habilitado = true
					});
				});
			})
		},
		buscarProdutos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.config.estoquesEditados = []
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/searchEstoqueProduto',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarSubcategorias : function (categoria) {
			if (categoria == null) {
				this.pesquisa.subcategoria = null
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
         this.$store.dispatch('getSubcategorias', categoria).then((data) => {
				this.listaSubcategorias = data

				if (data.length > 0) {
					this.pesquisa.subcategoria = data[0]
				}
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		addTemp : function (editado) {
			let index = this.config.estoquesEditados.findIndex(produto => produto.id == editado.id && produto.idEstoque == editado.idEstoque)
			
			if (index == -1) {
				this.config.estoquesEditados.push(editado)
			} else {
				this.$set(this.config.estoquesEditados, index, editado)
			}
		},
		removerTemp : function (index) {
			this.config.estoquesEditados.splice(index, 1)
		},
		getHistorico : function (produto) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)

			this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/getHistoricoEstoqueProduto',
				headers: {'Content-Type': 'application/json'},
				data: {'idProduto': produto.id}

			}).then(response => {
				this.modal = {
					'id': null, 
					'nome': produto.nome,
					'lojas': [], 
					'historico': response.data
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				$('#modalHistoricoEstoque').modal('show')
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarEstoqueProduto : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.config.estoquesEditados.forEach(produto => {
				produto.quantidade = (produto.quantidade == null ? null : String(produto.quantidade).replace(/,/g, "."))
			});
			
			this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/saveEstoqueProduto',
				headers: {'Content-Type': 'application/json'},
				data: this.config.estoquesEditados

			}).then(() => {
				$("#offcanvasEstoque").offcanvas("hide")
				this.buscarProdutos()
				
				this.$toast.fire({
					icon: 'success',
					title: 'Estoque salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
      this.$store.dispatch('getCategorias')
      this.$store.dispatch('getMarcas')
		this.buscarEstoques()
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>

<style scoped>

.form-check.form-switch {
	min-height: auto;
}

#estoque-tab .nav-link {
   line-height: 1;
   padding: 14px;
   text-align: start;
   font-weight: 500;
   text-transform: uppercase;
   font-size: 12px;
   transition: none !important;
	width: auto;
}

#estoque-tab .nav-link * {
   transition: none !important;
}

@media (min-width: 992px) {
   #estoque-tab ~ .col-lg {
      width: calc(100% - 225px);
   }

   #estoque-tab {
      width: 225px;
   }
}

</style>